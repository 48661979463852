@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;700&display=swap');

body {
  font-family: 'Inter', sans-serif;
}

.children-img {
  width: 700px;
}

.bg-primary {
  background-color: rgb(38, 177, 241) !important;
}

.bg-success {
  background-color: rgb(85, 170, 36) !important;
}

.bg-warning {
  background-color: rgb(247, 169, 3) !important;
}

.nav-link.active {
  color: rgb(36, 186, 255) !important;
}

.nav-link:hover {
  color: rgb(36, 186, 255) !important;
}

.carousel-linear-gradient {
  position: absolute;
  padding-top: 100px;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.85), transparent);
  left: 0;
  bottom: 0;
  width: 100vw;
}

.contact-icon-size {
  width: 100px;
  height: 100px;
}

.text-blue {
  color: rgb(38, 177, 241);
}

.text-align-justify {
  text-align: justify;
}

.img-lesson {
  transition: transform 0.5s ease;
}

.img-lesson:hover {
  transform: scale(1.1);
}

.workshop-img-height {
  height: 300px;
}

.event-img-height {
  height: 300px;
}

.atolye-img {
  height: 450px;
}



@media screen and (max-width: 600px) {
  .children-img {
    width: 300px;
  }

  .office-img {
    width: 300px;
  }

  .img-lesson {
    transition: none;
    height: 200px;
  }

  .img-lesson:hover {
    transform: none;
  }

  .carousel-img {
    height: 350px;
  }

  .icon-text-size-sm {
    font-size: 12px;
  }
  
  .icon-img-size-sm {
    width: 70px;
  }
  
  
}
